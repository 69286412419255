<template>
  <div class="base-preloader"
       v-if="visible">
    <img src="@/assets/img/logo-no-text.svg"
         alt="Loading..."
         class="logo-preloader"/>
  </div>
</template>

<script>
export default {
  name: 'BasePagePreloader',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(0.1);
    }
  }
  .base-preloader {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  .logo-preloader {
    width: 113px;
    height: 113px;
    animation: pulse 1.5s infinite ease-in-out;
  }
</style>
