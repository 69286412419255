<template>
  <div>
    <v-app-bar
      flat-1
      app
      elevation="1"
    >
      <v-app-bar-nav-icon @click.stop="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <img
          src="@/assets/img/logo.png"
          class="v-picker--full-width logoImg"
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <div>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-badge
                left
                bottom
                overlap
                dot
                :color="unviewedColor"
              >
                <template v-slot:badge>
                  <span></span>
                </template>
                <v-btn
                  icon
                  v-on="on"
                  @click="getUnviewedAdvert"
                  :disabled="loading"
                >
                  <v-icon> mdi-account-eye-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>
            Проверка актуальных объявлений для клиентов <br>
            <span class="tooltip-badge mr-1 red"></span> - таких нет <br>
            <span class="tooltip-badge mr-1 amber"></span> - есть у свободных клиентов <br>
            <span class="tooltip-badge mr-1 green"></span> - есть у своих клиентов <br>
            <strong> Нажать для активации новой проверки </strong>
          </v-tooltip>
        </div>
        <div class="mr-2">
          <v-badge
            left
            bottom
            overlap
            color="red"
          >
            <template v-slot:badge>
              <span
                v-if="unverifiedAdverts"
              >
                {{ unverifiedAdverts }}
              </span>
            </template>
            <v-btn icon>
              <v-icon> mdi-bell</v-icon>
            </v-btn>
          </v-badge>
        </div>
        <div class="d-flex ml-2">
          <v-icon>mdi-account</v-icon>
          <div class="pr-10 pl-2 pt-4 userName">
            <router-link :to="{name: 'profile'}">
              {{ userName }}
            </router-link>
          </div>
          <v-icon
            class="logOut"
            @click="logout"
          >mdi-logout
          </v-icon>
        </div>
      </div>
    </v-app-bar>
    <Navigation
      v-model="drawer"
    />
  </div>
</template>

<script>
import Navigation from './Navigation'
import Vue from 'vue'

export default {
  name: 'HeaderBlock',
  components: { Navigation },
  data () {
    return {
      drawer: false,
      unviewedAdvert: { own: false, free: false },
      loading: false
    }
  },
  computed: {
    userName () {
      return this.$store.getters['auth/getName']
    },
    unverifiedAdverts () {
      return this.$store.getters['table/getUnverifiedAdverts']
    },
    unviewedColor () {
      let color = 'red'
      if (this.unviewedAdvert.own) {
        color = 'green'
      } else if (this.unviewedAdvert.free) {
        color = 'amber'
      }
      return color
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    },
    async getUnviewedAdvert () {
      this.loading = true
      const response = await Vue.axios.get('clients-advert/unviewed')
      if (response.data.success) {
        this.unviewedAdvert = response.data.data
      }
      this.loading = false
      return true
    }
  },
  mounted () {
    this.getUnviewedAdvert()
  }
}
</script>

<style scoped>
.logoImg {
  width: 150px;
}
.userName {
  opacity: 0.7;
  font-size: 14px;
}
.count {
  position: absolute;
  width: 20px;
  font-size: 9px;
  font-weight: 800;
  color: #fff;
  height: 20px;
  bottom: -5px;
  left: 0;
  background: red;
  border-radius: 50%;
  padding: 4px 0;
  text-align: center;
}
.tooltip-badge {
  padding: 1px 11px;
  border-radius: 50%;
}
</style>
