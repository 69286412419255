<template>
  <div>
    <MessageSnack
      class="top-modifier"
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
    />
    <HeaderBlock/>
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MessageSnack from '@/components/layouts/admin/MessageSnack'
import HeaderBlock from '@/components/layouts/admin/HeaderBlock'

export default {
  name: 'admin-layout',
  components: {
    HeaderBlock,
    MessageSnack
  },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  },
  async mounted () {
    await Promise.all([
      this.$store.dispatch('table/checkUnverified'),
      this.$store.dispatch('serviceData/optionValues'),
      this.$store.dispatch('auth/fillProfile')
    ])
  }
}
</script>
