<template>
  <v-container
    class="auth-background fill-height d-flex align-center justify-center"
    fluid
  >
    <div class="overlay"/>

    <BasePagePreloader :visible="isLoading"/>

    <MessageSnack
      class="top-modifier"
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
      :message-index="index"
    />

    <div
      v-show="!isLoading"
      class="auth-card"
    >
      <router-view/>
    </div>
  </v-container>
</template>

<script>
import MessageSnack from '@/components/layouts/admin/MessageSnack'
import BasePagePreloader from '@/components/baseNew/BasePagePreloader.vue'

export default {
  name: 'auth-layout',
  components: { MessageSnack, BasePagePreloader },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.isLoading = false
      }, 1500)
    })
  }
}
</script>

<style scoped>
  .auth-background {
    background: url("~@/assets/img/bg.jpg") center/cover no-repeat;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 1;
  }
  .auth-card {
    position: relative;
    z-index: 2;
    background: rgba(255, 255, 255, 0.9);
    padding: 16px 12px;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 400px;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
  .auth-card[v-show="!isLoading"] {
    opacity: 1;
  }
  @media (max-width: 480px) {
    .auth-card {
      padding: 24px 16px;
      border-radius: 12px;
    }
  }
</style>
